import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import brand1 from '../../assets/images/brand1.png'
import brand2 from '../../assets/images/brand2.png'
import brand3 from '../../assets/images/brand3.jpg'
import brand4 from '../../assets/images/brand4.jpg'
import brand5 from '../../assets/images/brand5.jpg'
import brand6 from '../../assets/images/brand6.jpg'
import brand7 from '../../assets/images/brand7.jpg'
import brand8 from '../../assets/images/brand8.png'
import brand9 from '../../assets/images/brand9.jpg'
import brand10 from '../../assets/images/brand10.png'
import brand11 from '../../assets/images/brand11.jpg'
import brand12 from '../../assets/images/brand12.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

class BrandPage extends React.Component {
  render() {
    const siteTitle =
      'Gate Brands - Automatic Gates in Arlington, TX and Dallas-Fort Worth'
    const siteDescription =
      'Call 817-466-2794 for the Top Brands in Automatic Gates. Gate Designs, Services, and Installation in Dallas-Forth Worth, TX and Arlington, TX.'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="J&J Automatic Gate Service & Design"
          description={siteDescription}
        />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">
              Gate Brands We Service and Install
            </h1>
            <hr />
          </div>
        </div>

        <div className="container d-padding-t d-padding-b text-justify">
          <div className="row flex-column-reverse flex-md-row">
            <p className="mx-auto">
              J & J Gates Service and Design install and repairs all major gate
              brands. Liftmaster, Chamberlain, Elite, FAAC, Apollo, BFT, Eagle
              GTO, NICE–you name it, we do it. Not all gate service companies
              will work on gates they don’t install themselves. But we do. Your
              home will look gorgeous when one of the leading gate brands
              protect it. While gates work well for decorating your home,
              they’re not only for affluent homeowners. Leading gate brands also
              have products for budget-conscious customers who focus on the
              necessities.{' '}
              <strong>
                <em>
                  Contact J&J Gate Service at{' '}
                  <a href="tel:8174662794">817-466-2794</a> to learn about the
                  various gate brands we service and install in the Dallas-Fort
                  Worth, TX area
                </em>
              </strong>
              .
            </p>
            <div className="col-md-6">
              <h4 className="mt-0">All-O-Matic</h4>
              <p>
                All-O-Matic takes testing their gates and gate operators
                seriously, like no other company in the business. You never have
                to worry when you install All-O-Matic gates! Their operators
                function well on the tallest, longest, and heaviest gates
                around. And the company puts an intense focus on safety. In the
                rare event, something goes wrong with one of their gate
                operators, you have a strong warranty that protects you. But in
                most cases, All-O-Matic gates last decades with almost no
                issues.
              </p>
            </div>
            <div className="text-center col-md-6">
              <LazyLoadImage
                className="w-50 mt-5"
                src={brand1}
                alt="All-O-Matic"
              />
            </div>
          </div>

          <div className="row d-padding-t">
            <div className="text-center col-md-6">
              <LazyLoadImage
                className="w-50 mt-5"
                src={brand2}
                alt="Chamberlain"
              />
            </div>
            <div className="col-md-6">
              <h4 className="mt-0">Chamberlain Elite</h4>
              <p>
                Elite is one of the leading brands of all gate operators. And
                like other popular gate operator brands, they’re owned by the
                Chamberlain Group. One thing you’ll love about Elite–they have
                lengthy warranties on their products (these warranties aren’t
                usually necessary because the quality of their products is so
                high). One of the largest manufacturers in the industry, Elite
                makes gates and gate operators for all functions – high traffic,
                low traffic, residential, commercial, decorative, or even
                traffic control. You won’t go wrong when buying Chamberlain
                Elite Gates.
              </p>
            </div>
          </div>

          <div className="row d-padding-t flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <h4 className="mt-0">DC Solutions</h4>
              <p>
                DC Solutions gates specialties include making a barrier, swing,
                and slide gate operators for business and home use. They’re now
                owned by the Chamberlain Group, who also owns Liftmaster. DC
                Solutions’ product line is among the most reliable and highest
                quality in the industry. And in the rare situation where
                something goes wrong, they have excellent customer service.
                Their most popular products include the Mega Slide, Mega SlideX,
                Mega Swing, Mega Arm, and Mega Arm Sprint. Their products
                include:
              </p>
              <ul>
                <li>
                  <i class="fas fa-long-arrow-alt-right" /> Strong resistance to
                  corrosion
                </li>
                <li>
                  <i class="fas fa-long-arrow-alt-right" /> Beautiful appearance
                </li>
                <li>
                  <i class="fas fa-long-arrow-alt-right" /> Operate well for a
                  long time.
                </li>
              </ul>
            </div>
            <div className="text-center col-md-6">
              <LazyLoadImage
                className="w-50 mt-5"
                src={brand3}
                alt="DC Solutions"
              />
            </div>
          </div>

          <div className="row d-padding-t">
            <div className="text-center col-md-6">
              <LazyLoadImage
                className="w-50 mt-5"
                src={brand4}
                alt="Eagle Gate"
              />
            </div>
            <div className="col-md-6">
              <h4 className="mt-0">Eagle Gate Operators</h4>
              <p>
                You can get many types of gate operators with Eagle.
                Residential, commercial, and industrial options are available.
                The company’s the inventor of the “Diamond Control Board,” the
                most versatile of its kind you can get. They offer an
                exceptional warranty – 5 years minimum for all models. And 7
                years for any commercial models put in use for residential
                purposes. Eagle Gate Operators are committed to constant
                innovation and employs a staff especially dedicated to doing so.
                Any of Eagle Gate Operators gates you choose is an excellent
                choice–you can’t possibly go wrong!
              </p>
            </div>
          </div>

          <div className="row d-padding-t flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <h4 className="mt-0">Elite Gate Operators</h4>
              <p>
                Elite isn’t bragging with their company name – they really do
                offer some of the best gate operators you can get. They’re easy
                to maintain (requiring almost no maintenance), have an extended
                warranty, are quiet and fast, and they last for decades in many
                cases. You’ll pay up front, but the cost compared to other
                brands is more than worth it over the years. By the end of the
                Elite Gate operator’s and the gates’ life, they’ll have more
                than paid for themselves. They are one of the best investments
                your home or business can make if you must have gates.
              </p>
            </div>
            <div className="text-center col-md-6">
              <LazyLoadImage
                className="w-50 mt-5"
                src={brand5}
                alt="Elite Gate"
              />
            </div>
          </div>

          <div className="row d-padding-t">
            <div className="text-center col-md-6">
              <LazyLoadImage
                className="w-50 mt-5"
                src={brand6}
                alt="EMX Industries"
              />
            </div>
            <div className="col-md-6">
              <h4 className="mt-0">EMX Industrial Solutions</h4>
              <p>
                EMX specializes in making innovative access controls for gates.
                One of their more recent inventions is the ULT-PLG universal
                loop detector for gate operators. It has a unique feature,
                called “detect-on-stop” (the detector only recognizes vehicles
                that come to a complete stop on top of it). So if you have a lot
                of moving cross traffic in a tight space, it ignores that and
                focuses on stopped traffic only. It’s cost-effective and easy to
                install. EMX Industrial Solutions gates, sensors, and access
                controls often make a good gate even better. J & J Gate Service
                recommends many of their products, including this one, in their
                appropriate situations.
              </p>
            </div>
          </div>

          <div className="row d-padding-t flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <h4 className="mt-0">Liftmaster</h4>
              <p>
                Liftmaster is known for their awesome garage doors, but they
                make great gates too. Their gates can be made especially for
                your home or rural applications. You can go high-end and even
                talk to your visitors and allow them access through your
                smartphone (with select gate operators). They can also come with
                a solar option, which is among the most efficient available in
                the market. Their powerful P3 motors ensure your door or gate
                opens quickly and safely every single time. And their durable
                battery backup has one of the longest standby times you can
                find.
              </p>
            </div>
            <div className="text-center col-md-6">
              <LazyLoadImage
                className="w-50 mt-5"
                src={brand7}
                alt="Liftmaster"
              />
            </div>
          </div>

          <div className="row d-padding-t">
            <div className="text-center col-md-6">
              <LazyLoadImage className="w-50 mt-5" src={brand8} alt="Linear" />
            </div>
            <div className="col-md-6">
              <h4 className="mt-0">Linear</h4>
              <p>
                Linear gates and operators get made for the entire range of
                purposes – light residential use at a single-family home, to
                heavy commercial use at a highly-trafficked company. You won’t
                see the name “Linear” much anymore – they’re rebranding to
                “Nortek Security & Control.” But you can still expect the same
                impressive product quality. You’ll get everything you want in a
                gate – quiet, speedy operation, and secure and advanced controls
                for maximum functionality. You definitely can’t go wrong with
                Linear gates.
              </p>
            </div>
          </div>

          <div className="row d-padding-t flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <h4 className="mt-0">OSCO</h4>
              <p>
                OSCO gates have now joined with the Linear brand, but they’re
                still among the very best in the industry. They make gate
                operators for swing, slide, and barrier gates. That means they
                cover almost every application necessary. And since they’ve
                joined with another company, they can now offer you stellar
                customer service when you need it. One of their key benefits is
                you can tune their gate operators with remarkable precision few
                others can match. And they run on all power sources – AC, DC,
                and solar. OSCO gates always give you great value.
              </p>
            </div>
            <div className="text-center col-md-6">
              <LazyLoadImage
                className="w-50 mt-5 d-inner-padding-t"
                src={brand9}
                alt="OSCO"
              />
            </div>
          </div>

          <div className="row d-padding-t">
            <div className="text-center col-md-6">
              <LazyLoadImage className="w-50 mt-5" src={brand10} alt="Sentex" />
            </div>
            <div className="col-md-6">
              <h4 className="mt-0">Sentex</h4>
              <p>
                You can count on quality with Sentex gates and gate operators.
                They’re prepared to handle low traffic situations in
                single-family homes and high-traffic ones at busy companies and
                manufacturers. Their gates open and close correctly for years –
                including days and nights. Look out – their gate operators have
                such smooth, graceful, and reliable operation that neighbors
                sometimes get jealous! And if you own a business, you’ll impress
                your customers that much more. Contact us today at 817-466-2794
                to install your Sentex gates.
              </p>
            </div>
          </div>

          <div className="row d-padding-t flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <h4 className="mt-0">US Automatic</h4>
              <p>
                US Automatic gates are designed to be high-quality, while also
                drawing low amounts of power. The control boards their gates
                have come with microprocessors, which means they can offer you
                many new features and installation choices. Even though they’re
                low power, their gate operators swing open fast. Their “Ranger”
                series of operators, which operate on a 6-watt solar panel, can
                perform up to 65 cycles per day. That’s more than enough for any
                homeowner and would serve many businesses well. The “Patriot”
                series can use AC or solar power and comes with the benefit of a
                5-year control board/3-year all other components warranty.
                You’re always making a smart choice when you go with US
                Automatic gates.
              </p>
            </div>
            <div className="text-center col-md-6">
              <LazyLoadImage
                className="w-50 mt-5"
                src={brand11}
                alt="US Automatic"
              />
            </div>
          </div>

          <div className="row d-padding-t">
            <div className="text-center col-md-6">
              <LazyLoadImage className="w-50 mt-5" src={brand12} alt="Viking" />
            </div>
            <div className="col-md-6">
              <h4 className="mt-0">Viking</h4>
              <p>
                Viking gate operators and gates are one of the best values in
                the industry. You won’t pay as much as you would for leading
                brands, and Viking gate operates and gates last reasonably long.
                If you choose to do your own maintenance, they have great
                customer service. And just like you expect with other gates and
                gate operators, they’re quiet and fast. They require little
                maintenance long-term. So for budget-conscious homeowners and
                businesses who want a great deal, they make for the perfect
                solution!
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BrandPage

export const BrandPageQuery = graphql`
  query BrandPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
